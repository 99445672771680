import React, { useState, useEffect } from 'react'
import './auth.css'


import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import { faUser, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useHistory } from 'react-router-dom';
import { passwordValidator } from '../template/validators/validators';
import logo from '../template/img/logo.png';
import logoEsta from '../template/img/estacionamento.png';

//AQUI ESTAMOS IMPORTANDO NOSSO HOOCK CONTEXT PARA USO GLOBAL DO USUÁRIO LOGADO
import { useUser } from '../context/userContext';
import isAuthenticated from './isAuthenticated';

const baseURL = process.env.REACT_APP_AUTH_USER;

function Auth(props) {

    const { setUser } = useUser();
    const [userNameAuth, setUserNameAuth] = useState("");
    const [password, setPassworAuth] = useState("");
    const [remember, setRemember] = useState(false);

    const history = useHistory();

    const RedirectTo = () => {
        history.push("/home")
    }

    const addUser = (user) => {
        setUser(user);
        localStorage.setItem("user", user.userName)
        localStorage.setItem("email", user.email)
        localStorage.setItem("isAdmin", user.isAdmin)
        localStorage.setItem("welcome", true)
    }

    const cleanState = () => {
        setUserNameAuth("");
        setPassworAuth("");
    }

    const onSubmit = (event) => {
        event.preventDefault();
        login(userNameAuth, password);
    }

    useEffect(() => {
        document.title = "OCR | Login";
    });

    const verifyAuthenticated = async (user) => {
        const response = await isAuthenticated();
        if (response.data) {
            Swal.close();
            cleanState();
            addUser(user);
            sessionStorage.removeItem("token");
            if (remember) {
                localStorage.setItem("isLoggedIn", true);
                RedirectTo();
            } else {
                sessionStorage.setItem("isLoggedIn", true);
                RedirectTo();
            }
        } else {
            cleanState();
            localStorage.clear();
            sessionStorage.clear();
            Swal.fire({
                icon: 'error',
                showConfirmButton: false,
                title: 'Oops...',
                text: 'Token Inválido, tente novamente ou contate o administrador!',
                timer: 4000,
            })
            return;
        }
    }


    const login = (email, password) => {

        const basicAuth = btoa(email + ':' + password);
        const stringSenha = passwordValidator(password);

        if (stringSenha) {
            Swal.fire({
                title: 'Entrando...'
            });
            Swal.showLoading();
            setTimeout(() => {
                axios.post(baseURL, null, {
                    params: {
                        'infoUser': 'Basic ' + basicAuth
                    }

                }).then(response => {
                    sessionStorage.setItem("token", response.data.token)
                    verifyAuthenticated(response.data);
                }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        showConfirmButton: false,
                        title: 'Oops...',
                        text: 'Usuário ou senha invalido!',
                        footer: 'Todos os campos são obrigatórios o preenchimento',
                        timer: 3000,
                    })
                    return false;
                });
            }, 5000)
        } else {

            Swal.fire({
                icon: 'error',
                showConfirmButton: false,
                title: 'Oops...',
                text: 'Número de caracteres inválido para senha.',
                footer: 'Verifique o requerimento para o campo senha.',
                timer: 5000,
            })
            return false;
        }
    }

    return (
        <div className="container form">
            <div className="row login-form" style={{ margin: '0 auto', marginTop: 220, height: 490 }}>
                <form className="container" onSubmit={onSubmit}>
                    <img src={logo} alt="SemParar" width={100} style={{ marginLeft: 82, marginBottom: 20 }} />
                    <img src={logoEsta} alt="SemParar" width={100} style={{ marginLeft: 0, marginBottom: 20 }} />
                    <div className="form-group">
                        <label htmlFor="userName">Usuário</label>
                        <div className="input-group flex-nowrap">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping-userName"><FontAwesomeIcon icon={faUser} /></span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                name="userName"
                                placeholder="Enter User Name"
                                aria-label="userName"
                                aria-describedby="addon-wrapping"
                                onChange={e => setUserNameAuth(e.target.value)}
                                value={userNameAuth}
                                required />
                        </div>
                        <small id="emailHelp" className="form-text text-muted">Seus dados serão mantidos em sigilo.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="senha">Senha</label>
                        <div className="input-group flex-nowrap">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUnlock} /></span>
                            </div>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                onChange={e => setPassworAuth(e.target.value)}
                                value={password}
                                required />
                        </div>
                        <small id="emailHelp" className="form-text text-muted">Mínimo de 8 caracteres, letra maiúscula, letra minúscula, um dígito e um caractere especial .</small>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="remember" name="remember" value={remember} onChange={e => setRemember(e.target.checked)}/>
                        <label className="form-check-label" htmlFor="lembrar">Lembrar-me</label>
                    </div>
                    <button className="btn btn-info mt-4" type="submit" style={{ width: '100%', margin: '0 auto', height: 40 }}>Entrar</button>
                </form>
            </div>
        </div>
    )
}

export default Auth;