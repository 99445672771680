/************** VALIDADORES *************************/
export function validaEmail (strEmail) {
    var usuario = strEmail.substring(0, strEmail.indexOf("@"));
    var dominio = strEmail.substring(
      strEmail.indexOf("@") + 1,
      strEmail.length
    );

    if (
      usuario.length >= 1 &&
      dominio.length >= 3 &&
      usuario.search("@") === -1 &&
      dominio.search("@") === -1 &&
      usuario.search(" ") === -1 &&
      dominio.search(" ") === -1 &&
      dominio.search(".") !== -1 &&
      dominio.indexOf(".") >= 1 &&
      dominio.lastIndexOf(".") < dominio.length - 1
    ) {
      return true;
    }
    return false;
}

export function passwordValidator (strPassword) {

  var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if(strPassword.length < 8)
  {
    return false;
  }
  else if(!regex.exec(strPassword))
  {   
    return false;
  }
  return true;
}