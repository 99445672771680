import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

import SideBar from '../template/sidebar/sidebar';
import NotFound from '../template/Error/notFound';

import { faUser, faEnvelope, faUnlockAlt, faUserShield, faSearch, faUserEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from '../template/img/logo.png';

const $ = require('jquery');

const userRole = localStorage.getItem("isAdmin");
const RoleCurrent = userRole === "true" ? true : userRole === "null" ? null : false;
const activeOrDisabled = userRole === "true" ? "admin.empresa" : userRole === "null" ? "admin.geral" : "user.comum";
const baseAddressProfile = process.env.REACT_APP_PROFILE_USER;
const baseAddressSaveEditChanges = process.env.REACT_APP_USER_UPDATE;


function UserEdit(props) {

    const [nameEdit, setNameEdit] = useState("");
    const [emailEdit, setEmailEdit] = useState("");
    const [passwordEdit, setPasswordEdit] = useState("");
    const [isAdminEdit, setIsAdminEdit] = useState("");

    const [userNameModalSearch, setUserNameModalSearch] = useState("");
    const [userEmailModalSearch, setUserEmailModalSearch] = useState("");


   /*******************************
    *  HandleSubmit Edit User     *
    *******************************/
    const handleSubmit = (e) => {
        e.preventDefault();
        getUserEdit();
    }


   /*******************************
    *  HandleSubmit Edit User     *
    *******************************/
    const handleModalSearch = (e) => {
        e.preventDefault();
        getUserEdit()
    }


    /*******************************
    *    Clean State Current      *
    *******************************/
    const cleanState = () => {
        setNameEdit("");
        setEmailEdit("");
        setPasswordEdit("")
        setIsAdminEdit("");
    }


    /*******************************
    *    Clean Input Search      *
    *******************************/
    const cleanInputSearch = () => {
        setUserNameModalSearch("");
        setUserEmailModalSearch("");
    }


   /*******************************
    *    Save Changes User Input  *
    *******************************/
    const handleSaveEditChanges = () => {

        Swal.fire({
            title: 'Salvando alterações...'
        });
        Swal.showLoading();
        setTimeout(() => {

            axios.post(baseAddressSaveEditChanges, null, {
                params: {
                    nome: nameEdit,
                    email: emailEdit,
                    password: passwordEdit,
                    isAdmin: isAdminEdit
                }
            }).then(response => {
                Swal.close()
                setTimeout(function () {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    cleanState();
                }, 2000);
            }).catch(error => {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Oops... Falha ao atualizar usuário',
                    text: "Tente novamente ou contate o administrador!",
                    timer: 3000,
                })
                cleanState();
            });

        }, 5000)
    }


   /*******************************
    *    SetData User on the      *
    *      State Current          *
    *******************************/
    const dataUserProfile = (user) => {
        setNameEdit(user[0]["userName"]);
        setEmailEdit(user[0]["email"]);
        setPasswordEdit(user[0]["password"])
        setIsAdminEdit(user[0]["isAdmin"]);
    }




   /*******************************
    *    Search User Input        *
    *******************************/
    const getUserEdit = () => {
        Swal.fire({
            title: 'Buscando...'
        });
        Swal.showLoading();
        setTimeout(() => {
            axios.post(baseAddressProfile, null, {
                params: {
                    nome: userNameModalSearch,
                    email: userEmailModalSearch,
                }
            }).then(response => {
                Swal.close()
                dataUserProfile(response.data)
                $('#modalSearchUser').modal('hide');
            }).catch(error => {
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Oops...',
                    text: "Erro ao encontrar usuário, verifique se o usuário está correto!",
                    timer: 3000,
                })
            });
        }, 5000)
    }

    const disabledButton = (data) => {
        document.getElementById("saveChanges").disabled = data;
    }

    useEffect(() => {
        if(activeOrDisabled === "admin.empresa" || activeOrDisabled === "admin.geral"){
            disabledButton(true);
        }else{
            return false;
        }
        if (nameEdit && emailEdit && passwordEdit) {
            disabledButton(false);
        }
    }, [nameEdit, emailEdit, passwordEdit])

    return (
        <>

            { RoleCurrent || RoleCurrent === null ?

                (
                    <>
                        <SideBar />
                        <div className="container">
                            <section id="content-wrapper">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form">
                                            <div className="row login-form" style={{ margin: '0 auto', marginTop: 90, height: 600 }}>
                                                <form className="container" onSubmit={handleSubmit}>
                                                    <img src={logo} alt="SemParar" width={100} style={{ marginLeft: 125, marginBottom: 20 }} />
                                                    <div className="col-sm-12 col-md-10 col-lg-8 perfilUser" align="center">
                                                        <span className="badge badge-pill badge-info">Editar Usuário</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="nameProfile">Usuário</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-nameProfile"><FontAwesomeIcon icon={faUser} /></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                onChange={e => setNameEdit(e.target.value)}
                                                                value={nameEdit}
                                                                className="form-control"
                                                                id="name"
                                                                name="nameProfile"
                                                                placeholder="Enter User Name"
                                                                aria-label="name"
                                                                aria-describedby="addon-wrapping"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="emailProfile">Email</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faEnvelope} /></span>
                                                            </div>
                                                            <input
                                                                type="email"
                                                                onChange={e => setEmailEdit(e.target.value)}
                                                                value={emailEdit}
                                                                className="form-control"
                                                                id="emailProfile"
                                                                name="emailProfile"
                                                                placeholder="Enter email"
                                                                aria-label="email"
                                                                aria-describedby="addon-wrapping"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="Password">Senha</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-passwordConfirm"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                            </div>
                                                            <input
                                                                type="password"
                                                                onChange={e => setPasswordEdit(e.target.value)}
                                                                value={passwordEdit}
                                                                className="form-control"
                                                                name="PasswordEdit"
                                                                id="PasswordEdit"
                                                                placeholder="Password"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group" id="activeIsAdmin">
                                                        <label htmlFor="inputState">Admin</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUserShield} /></span>
                                                            </div>
                                                            <select id="isAdmin" className="form-control" value={isAdminEdit} onChange={e => setIsAdminEdit(e.target.value)}>
                                                                <option defaultValue>Selecione</option>
                                                                <option value={true} >Sim</option>
                                                                <option value={false}>Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="ml-auto mr-2">
                                                    <button type="button" className="btn btn-success" data-toggle="modal" data-target="#modalSearchUser">Pesquisar <FontAwesomeIcon icon={faSearch} /></button>
                                                </div>
                                                <div className="mr-auto">
                                                    <button type="button" className="btn btn-info" id="saveChanges" onClick={handleSaveEditChanges}>Salvar alterações <FontAwesomeIcon icon={faUserEdit} /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="container">
                            <div className="modal fade" id="modalSearchUser" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLongTitle">Pesquisar Usuário</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="container" onSubmit={handleSubmit}>
                                                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                                                    <label htmlFor="nameModalSearch">Usuário</label>
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping-nameModalSearch"><FontAwesomeIcon icon={faUser} /></span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            onChange={e => setUserNameModalSearch(e.target.value)}
                                                            value={userNameModalSearch}
                                                            className="form-control"
                                                            name="nameModalSearch"
                                                            placeholder="Enter User Name"
                                                            aria-label="nameModalSearch"
                                                            aria-describedby="addon-wrapping"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                                                    <label htmlFor="EmailModalSearch">Email</label>
                                                    <div className="input-group flex-nowrap">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="addon-wrapping-EmailModalSearch"><FontAwesomeIcon icon={faEnvelope} /></span>
                                                        </div>
                                                        <input
                                                            type="email"
                                                            onChange={e => setUserEmailModalSearch(e.target.value)}
                                                            value={userEmailModalSearch}
                                                            className="form-control"
                                                            name="EmailModalSearch"
                                                            placeholder="Enter email"
                                                            aria-label="EmailModalSearch"
                                                            aria-describedby="addon-wrapping"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className="mr-auto">
                                                <button type="button" className="btn btn-secondary" onClick={cleanInputSearch}>Limpar <FontAwesomeIcon icon={faRedo} /></button>
                                            </div>
                                            <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
                                            <button type="button" className="btn btn-info" onClick={handleModalSearch}>Buscar <FontAwesomeIcon icon={faSearch} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )
                :
                (
                    <NotFound />
                )
            }
        </>
    )
}

export default UserEdit;