import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RoutePrivate = (props) => {
    const { component: RouteComponent, ...other } = props;
    const isLoggedIn = !!sessionStorage.getItem("isLoggedIn") || !!localStorage.getItem("isLoggedIn");
    const currentUser = !!localStorage.getItem("user") && !!localStorage.getItem("email") && !!localStorage.getItem("isAdmin");

    return(
        <Route
            //AQUI EM OTHER SERÁ VERIFICADO SE FOI PASSADO ALGUM OUTRO PARAMETRO DIFERENTE DE UM RouteComponent
            //SERA ELE RECEBEU ELE IRÁ PASSAR ESSAS PROPRIEDADES EXTRAS PARA ESSE COMPONENT ROUTER
            {...other}
            render={
                routeProps => isLoggedIn && currentUser ? ( <RouteComponent {...routeProps}/> ) : ( <Redirect to="/login" /> )
            }
        />
    );
};

export default RoutePrivate;