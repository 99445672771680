import axios from 'axios';

const baseURL = process.env.REACT_APP_URL_VALIDATE_TOKEN;

async function isAuthenticated(){
    
    const token = sessionStorage.getItem("token");

    try {
        const response = await axios.post(baseURL, null, {
            params:{
                token: token != null ? token : null
            }
        });
        return response
    }catch(error) {
        console.error(error);
        return false;
    }  
}

export default isAuthenticated;