import React, { Component } from 'react'
import './table.css'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import '../template/dependencies/fontAwesome/css/fontawesome_all.css'
import { saveAs } from 'file-saver';

import { faFileDownload, faSearch, faSearchPlus, faStore, faBarcode, faCar, faBroadcastTower } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios'
const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');


const convertDate = (datahora) => {

    var dttime_year = datahora.substr(0, 4);
    var dttime_month = datahora.substr(5, 2);
    var dttime_day = datahora.substr(8, 2);
    var dttime_hour = datahora.substr(11, 2);
    var dttime_min = datahora.substr(14, 2);
    return dttime_day + '/' + dttime_month + '/' + dttime_year + ' ' + dttime_hour + ':' + dttime_min;
}

const date = () => {
    var date = new Date();
    const dateInitialDom = date.toISOString().substring(0, 10);
    return dateInitialDom
}


const baseAddressFilter = process.env.REACT_APP_URL_OCR_FILTER;
const baseAddressExport = process.env.REACT_APP_EXPORT_PASSAGES;
let table = undefined;

class Table extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DataInicial: "",
            DataFinal: "",
            Estabelecimento: "",
            Dispositivo: "",
            Placa: "",
            Igualdade: ""
        }
    }

    disabledButton = (data) => {
        document.getElementById("searchFilter").disabled = data;
    }

    newSearch = () => {
        this.setState({
            Estabelecimento: "",
            Dispositivo: "",
            Placa: "",
            Igualdade: ""
        });
        $('#passages').DataTable().clear().destroy();
        this.disabledButton(false);
        this.disabledDatatableButton(true);
    }

    disabledDatatableButton = (data) => {
        document.getElementById("PassagesBtn").disabled = data;
        document.getElementById("newSearch").disabled = data;
    }


    onChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    onSubmitPassages = (event) => {
        event.preventDefault();
        const { DataInicial, DataFinal, Estabelecimento, Dispositivo, Placa, Igualdade } = this.state
        this.searchPassages(DataInicial, DataFinal, Estabelecimento, Dispositivo, Placa, Igualdade)
    }
    componentDidMount() {

        document.title = "OCR | Home";
        //VALOR INICIAL CAMPOS INPUT DATETIME
        var date = new Date();

        this.disabledDatatableButton(true);
        var dateInitialDom = document.querySelector('input[id="dateInitial"]');
        dateInitialDom.value = date.toISOString().substring(0, 10);
        this.setState({ DataInicial: dateInitialDom.value});
    
        var dateEndDom = document.querySelector('input[id="dateEnd"]');
        dateEndDom.value = date.toISOString().substring(0, 10);
        this.setState({ DataFinal: dateEndDom.value});

        //EVENT CLICK MOSTRA FOTO 
        $('#passages').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            const data = row.data();
            var href = $(data["ocrPhoto"]).attr('href');
            window.open(href, '_blank');
        });
        
    }

    exportCsvPassages = () => {
        const { DataInicial, DataFinal, Estabelecimento, Dispositivo, Placa, Igualdade } = this.state
        Swal.fire({
            title: 'Processando...'
        });
        Swal.showLoading();
        axios.get(baseAddressExport, {
            params: {
                "dateini": DataInicial,
                "datefin": DataFinal,
                "place": Estabelecimento,
                "device": Dispositivo,
                "plate": Placa,
                "match": Igualdade
            }
        }).then(response => {

            var blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
            saveAs(blob, "Passagens DriveThru.txt");
            Swal.close();

        }).catch(error => {
            Swal.close();
            console.log(error)
        });
    }

    searchPassages = (DataInicial, DataFinal, Estabelecimento, Dispositivo, Placa, Igualdade) => {
        //SWET ALERT TIMER REQUEST
        let timerInterval
        Swal.fire({
            title: 'Por favor Aguarde o Termino da solicitação!',
            timer: 5000,
            willOpen: () => {
                Swal.showLoading()
                timerInterval = setInterval(() => {
                    const content = Swal.getContent()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 8000)
            },
            willClose: () => {
                clearInterval(timerInterval)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Solicitação realizada com Sucesso',
                    showConfirmButton: false,
                    timer: 4000
                });
            }
        }).then(result => {

            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('Success')
            }
        })

        try {
            this.disabledButton(true);
            this.disabledDatatableButton(false);
            this.$tablePassages = $(this.tablePassages)
            table = this.$tablePassages.DataTable({
                createdRow: function (row, data, index) {
                    if (data["match"] == false) {
                        $("td", row)
                            .eq(7)
                            .addClass(
                                "text-uppercase text-center text-danger font-weight-bold"
                            );
                    } else {
                        $("td", row)
                            .eq(7)
                            .addClass(
                                "text-uppercase text-center text-success font-weight-bold"
                            );
                    }
                    if (data["transaction"] == false) {
                        $("td", row)
                            .eq(8)
                            .addClass(
                                "text-uppercase text-center text-danger font-weight-bold"
                            );
                    } else {
                        $("td", row)
                            .eq(8)
                            .addClass(
                                "text-uppercase text-center text-success font-weight-bold"
                            );
                    }
                    if (data["contador"] > 4) {
                        $("td", row).eq(9).addClass("text-uppercase text-center text-success font-weight-bold");
                    }
                    else {
                        $("td", row).eq(9).addClass("text-uppercase text-center text-danger font-weight-bold");
                    }
                },
                "processing": true,
                "serverSide": true,
                "select": "single",
                ajax: {
                    url: baseAddressFilter,
                    type: "POST",
                    headers: {
                        "x-api-key": "TmBICIuq7f8sxnDKgqDsuaDSLAsoEMJS6OExePAP",
                    },
                    dataType: "json",
                    contentType: "application/json",
                    data: function (data) {
                        date();
                        data.DateIni = DataInicial === "" | null ? date() : DataInicial;
                        data.DateEnd = DataFinal === "" | null ? date() : DataFinal;
                        data.Place = Estabelecimento;
                        data.Device = Dispositivo;
                        data.SemPararPlate = Placa;
                        data.Match = Igualdade;
                        return JSON.stringify(data);
                    },
                    error: function (errors) {
                        var msg = '';
                        if (errors.status === 0) {
                            msg = 'Não a conexão. \n Verifique a rede';
                        } else if (errors.status === 404) {
                            msg = 'Página solicitada não encontrada. [404]';
                        } else if (errors.status === 500) {
                            msg = 'Erro interno do servidor [500].';
                        } else {
                            msg = 'Uncaught Error.\n' + errors.responseText;
                        }
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: msg,
                            footer: 'Tente novamente!',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                },

                "bStateSave": true,
                "paging": true,
                "ordering": true,
                "info": true,
                "searching": { regex: true },
                "responsive": true,
                "pageLength": 50,
                "dom": "Bfrtip",
                "columns": [
                    {
                        "className": 'details-control',
                        "orderable": false,
                        "data": null,
                        "defaultContent": '',
                        "render": function () {
                            return '<i class="fa fa-plus-square" aria-hidden="true"></i>';
                        },
                        "width": "15px"
                    },
                    { data: "place" },
                    { data: "lane" },
                    { data: "dateCreated" },
                    { data: "device" },
                    { data: "semPararPlate" },
                    { data: "ocrPlate" },
                    { data: "match" },
                    { data: "transaction" },
                    { data: "contador" }
                ],
                "columnDefs": [
                    {
                        targets: 3, render: function (data) {
                            const dateTime = convertDate(data);
                            return dateTime;
                        },
                    },
                    {
                        targets: 8, render: function (data) {
                            return data == null ? '' : data == false ? 'Autorização' : 'Transação';
                        }
                    },
                    {
                        targets: 9, render: function (data) {
                            const countFormat = data >= 5 ? true : data
                            return countFormat
                        }
                    }
                ],
                "order": [[3, "desc"]],
                "language": {
                    decimal: "",
                    emptyTable: "Nenhum dado para ser exibido",
                    info: "Exibindo _START_ para _END_ de _TOTAL_ registros",
                    infoEmpty: "Exibindo 0 para 0 de 0 registros",
                    infoFiltered: "(Filtrado de _MAX_ total de registros)",
                    infoPostFix: "",
                    thousands: ",",
                    lengthMenu: "Exibir _MENU_ registros",
                    loadingRecords: "Carregando...",
                    processing: '<i class="fas fa-spinner fa-pulse fa-4x""></i>',
                    search: "Pesquisar:",
                    zeroRecords: "Nenhum dado pesquisado encontrado",
                    paginate: {
                        first: "Primeiro",
                        last: "Último",
                        next: "Próximo",
                        previous: "Anterior",
                    },
                    aria: {
                        sortAscending: ": activate to sort column ascending",
                        sortDescending: ": activate to sort column descending",
                    },
                },
            });
        } catch (e) {
        }
    }

    render() {
        return (
            <div id="wrapper">
                <div className="container-fluid" style={{ marginTop: 100 }}>
                    <div className="table-wrapper table-responsive-xl">
                        <div className="row justify-content-center mb-5 mt-5">
                            <div className="col-sm-12 col-md-10 col-lg-8 mr-auto">
                                <h2><span className="badge badge-pill badge-secondary">Tabela de Passages</span></h2>
                                <div className="container-fluid bg-secondary linecolortable"></div>
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-8">
                                <form onSubmit={this.onSubmitPassages} className="formtablepassages">
                                    <div className="form-row">
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="DataInicial">Data Inicial:</label>
                                            <input type="date" className="form-control" name="DataInicial" id="dateInitial" onChange={this.onChange} value={this.state.DataInicial} required />
                                        </div>
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="DataFinal">Data Final:</label>
                                            <input type="date" className="form-control" name="DataFinal" id="dateEnd" onChange={this.onChange} value={this.state.DataFinal} required />
                                        </div>
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="Estabelecimento">Estabelecimento</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faStore} /></span>
                                                </div>
                                                <input type="number" className="form-control" name="Estabelecimento" onChange={this.onChange} value={this.state.Estabelecimento} id="Estabelecimento" placeholder="Buscar" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="Dispositivo">Dispositivo</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faBarcode} /></span>
                                                </div>
                                                <input type="number" className="form-control" name="Dispositivo" onChange={this.onChange} value={this.state.Dispositivo} id="Dispositivo" placeholder="Buscar" />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="Placa">Placa</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faCar} /></span>
                                                </div>
                                                <input type="text" className="form-control" name="Placa" onChange={this.onChange} value={this.state.Placa} id="Placa" placeholder="Buscar" maxLength="8" />
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-4 col-md-4">
                                            <label htmlFor="match">Igualdade</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faBroadcastTower} /></span>
                                                </div>
                                                <select className="form-control" value={this.state.Igualdade} onChange={this.onChange} name="Igualdade">
                                                    <option value={""} defaultValue>Selecione</option>
                                                    <option value={true} >Sim</option>
                                                    <option value={false}>Não</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn bg-success text-light" id="searchFilter">Pesquisar <FontAwesomeIcon icon={faSearch} /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div>
                            <button type="button" className="btn bg-info text-light" onClick={this.exportCsvPassages} id="PassagesBtn">Baixar Todos <FontAwesomeIcon icon={faFileDownload} /></button>
                            <button type="button" className="btn bg-danger text-light" onClick={this.newSearch} id="newSearch">Nova Consulta <FontAwesomeIcon icon={faSearchPlus} /></button>
                            <table className="table table-striped table-bordered responsive" width="100%" ref={tablePassages => this.tablePassages = tablePassages} id="passages">
                                <thead>
                                    <tr>
                                        <th>
                                            <center>
                                                <h5>+</h5>
                                            </center>
                                        </th>
                                        <th>Estabelecimento</th>
                                        <th>Pista</th>
                                        <th>Data Gravação</th>
                                        <th>Dispositivo</th>
                                        <th>Placa SemParar</th>
                                        <th>Placa Identificada</th>
                                        <th>Igualdade</th>
                                        <th>Foto Capturada</th>
                                        <th>Contador</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Table;