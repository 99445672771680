import React from 'react';
import { Link } from 'react-router-dom';

import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotFound = () => (
  <div>
    <div className="container">
      <div align="center" style={{ marginTop: 350 }}>
        <h1 className="font-weight-bold" style={{ fontSize: '4em', color: 'rgb(58, 58, 58)' }}>Não autorizado <FontAwesomeIcon icon={faUserLock}/></h1>
        <Link to="/home" className="font-weight-bold" style={{ fontSize: '2em'}}>Home</Link>
      </div>
    </div>
  </div>
);

export default NotFound;