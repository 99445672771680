import React, { Component } from 'react';

import './list.css';

import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

import SideBar from '../template/sidebar/sidebar';
import NotFound from '../template/Error/notFound';


const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');


const convertDate = (datahora) => {

    var dttime_year = datahora.substr(0, 4);
    var dttime_month = datahora.substr(5, 2);
    var dttime_day = datahora.substr(8, 2);
    var dttime_hour = datahora.substr(11, 2);
    var dttime_min = datahora.substr(14, 2);
    return dttime_day + '/' + dttime_month + '/' + dttime_year + ' ' + dttime_hour + ':' + dttime_min;
}

const userRole = localStorage.getItem("isAdmin");
const RoleCurrent = userRole === "null" ? null : false;
const baseAddressList = process.env.REACT_APP_LIST_USER;
const baseAddressDeleteUser = process.env.REACT_APP_USER_DELETE;
const baseAddressResetPassword = process.env.REACT_APP_USER_RESET_PASSWORD;
let table = undefined;

class ListUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            list: [],
        }
    }

    updateList = () => {
        this.userListTable(this.state.list)
    }

    componentDidMount() {

       /*******************************
        *                             *
        *   Title Component Load      *
        *                             *
        *******************************/
        document.title = "OCR | Lista de usuários";
       /*******************************
        *                             *
        *   Get Users List Backend    *
        *                             *
        *******************************/
        axios.get(baseAddressList, null, {
        }).then(response => {
            this.setState({ list: response.data })
            this.updateList()
        }).catch(error => {
            console.log(error)
        });

       /*******************************
        *       Delete User on the    *
        *          event click        *
        *******************************/
        $('table').on('click', 'button.delete', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var rowData = row.data();
            Swal.fire({
                title: 'Deseja excluir esse usuário ?',
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: `Sim`,
                denyButtonText: `Não`,
            }).then(result => {
                if (result.value) {
                    Swal.fire({
                        title: 'Deletendo...'
                    });
                    Swal.showLoading();
                    setTimeout(() => {

                        axios.post(baseAddressDeleteUser, null, {
                            params: {
                                id: rowData.id,
                                userName: rowData.userName
                            }
                        }).then(response => {
                            Swal.close()
                            setTimeout(function () {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: response.data,
                                    showConfirmButton: false,
                                    timer: 4000
                                })
                            }, 2000);
                            setTimeout(function () {
                                window.location.reload();
                            }, 4000);
                        }).catch(error => {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                showConfirmButton: false,
                                title: 'Oops... Falha ao excluir usuário',
                                text: "Tente novamente ou contate o administrador!",
                                timer: 3000,
                            })
                        });

                    }, 5000)
                } else if (result.isDenied) {
                    return;
                }
            })
        });


       /*******************************
        *     Refresh password User   *
        *      on the event click     *
        *******************************/
        $('table').on('click', 'button.refresh', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var rowData = row.data();
            Swal.fire({
                title: 'Deseja resetar a senha desse usuário ?',
                icon: 'info',
                showDenyButton: true,
                confirmButtonText: `Sim`,
                denyButtonText: `Não`,
            }).then(result => {
                if (result.value) {
                    Swal.fire({
                        title: 'Resetando...'
                    });
                    Swal.showLoading();
                    setTimeout(() => {

                        axios.post(baseAddressResetPassword, null, {
                            params: {
                                nome: rowData.userName,
                                email: rowData.email
                            }
                        }).then(response => {
                            Swal.close()
                            setTimeout(function () {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: response.data,
                                    showConfirmButton: false,
                                    timer: 100000
                                })
                            }, 2000);
                        }).catch(error => {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                showConfirmButton: false,
                                title: 'Oops... Falha ao Resetar senha',
                                text: "Tente novamente ou contate o administrador!",
                                timer: 3000,
                            })
                        });
                    }, 5000)
                } else if (result.isDenied) {
                    return;
                }
            })
        });
    }


       /*******************************
        *     Table Users List        *
        *******************************/
    userListTable = (data) => {
        try {
            this.$tableUsers = $(this.tableUsers)
            table = this.$tableUsers.DataTable({

                "processing": true,
                "select": "single",
                "bStateSave": true,
                "paging": true,
                "ordering": true,
                "info": true,
                "searching": true,
                "responsive": true,
                "pageLength": 50,
                "data": data,
                "rowId": 'id',
                "dom": "Bfrtip",
                "columns": [
                    { data: "id" },
                    { data: "userName" },
                    { data: "email" },
                    { data: "password" },
                    { data: "created_at" },
                    { data: "isAdmin" },
                    {
                        data: null,
                        render: function (data, type, row) {
                            return '<div class="actions"><button class="btn bg-success text-white btn-sm refresh"><i class="fas fa-sync"></i></button> <button class="btn bg-danger btn-sm text-white delete"><i class="fas fa-trash"></i></button></div>';
                        },
                    },
                ],
                "columnDefs": [
                    {
                        targets: 1, render: function (data) {
                            return `<i class="fas fa-user username"></i>${data}`;
                        },
                    },
                    {
                        targets: 2, render: function (data) {
                            return `<i class="fas fa-envelope email"></i>${data}`;
                        },
                    },
                    {
                        targets: 3, render: function (data) {
                            return `<i class="fas fa-lock password"></i>${"********"}`;
                        },
                    },
                    {
                        targets: 4, render: function (data) {
                            const dateTime = convertDate(data);
                            return `<i class="fas fa-table date"></i>${dateTime}`;
                        },
                    },
                    {
                        targets: 5, render: function (data) {
                            const IsAdmin = data === true ? '<span class="badge badge-warning">Admin Empresa</span>' : data === null ? '<span class="badge badge-success">Admin Geral</span>' : '<span class="badge badge-info">Usuário</span>'
                            return IsAdmin;
                        },
                    },
                ],
                "order": [[3, "desc"]],
                "language": {
                    decimal: "",
                    emptyTable: "Nenhum dado para ser exibido",
                    info: "Exibindo _START_ para _END_ de _TOTAL_ registros",
                    infoEmpty: "Exibindo 0 para 0 de 0 registros",
                    infoFiltered: "(Filtrado de _MAX_ total de registros)",
                    infoPostFix: "",
                    thousands: ",",
                    lengthMenu: "Exibir _MENU_ registros",
                    loadingRecords: "Carregando...",
                    processing: '<i class="fas fa-spinner fa-pulse fa-4x""></i>',
                    search: "Pesquisar:",
                    zeroRecords: "Nenhum dado pesquisado encontrado",
                    paginate: {
                        first: "Primeiro",
                        last: "Último",
                        next: "Próximo",
                        previous: "Anterior",
                    },
                    aria: {
                        sortAscending: ": activate to sort column ascending",
                        sortDescending: ": activate to sort column descending",
                    },
                },
            });
        } catch (e) {
        }
    }

    render() {
        return (
            <>
                { RoleCurrent === null ?
                    (
                        <>
                            <SideBar />
                            <div id="wrapper">
                                <div className="container-fluid" style={{ marginTop: 100 }}>
                                    <div className="table-wrapper table-responsive-xl">
                                        <div className="row justify-content-center mb-5 mt-5">
                                            <div className="col-sm-12 col-md-10 col-lg-8 mr-auto">
                                                <h2><span className="badge badge-pill badge-secondary">Usuários Ativos</span></h2>
                                                <div className="container-fluid bg-secondary lineColorlist"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped table-bordered responsive" width="100%" ref={tableUsers => this.tableUsers = tableUsers} id="userslist">
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Nome de Usuário</th>
                                                        <th>Email</th>
                                                        <th>Senha</th>
                                                        <th>Data de Criação</th>
                                                        <th>Adminstrador</th>
                                                        <th width="90px">Ações</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <NotFound />
                    )
                }
            </>
        )
    }
}

export default ListUser;