import React, { useState, useEffect } from 'react';
import '../auth/auth.css';

import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import { faUser, faEnvelope, faUnlockAlt, faUserShield, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validaEmail, passwordValidator } from '../template/validators/validators';

import logo from '../template/img/logo.png';
import SideBar from '../template/sidebar/sidebar';

const $ = require('jquery');

const userName = localStorage.getItem("user");
const userEmail = localStorage.getItem("email");
const baseAddressProfile = process.env.REACT_APP_PROFILE_USER;
const baseAddressUpdateUser = process.env.REACT_APP_USER_UPDATE;

function UpdateProfile(props) {

    const [nameProfile, setNameProfile] = useState("");
    const [emailProfile, setEmailProfile] = useState("");
    const [passwordProfile, setPasswordProfile] = useState("");
    const [confirmPasswordProfile, setConfirmPasswordProfile] = useState("");
    const [oldPasswordProfile, setOldPasswordProfile] = useState("");
    const [isAdminProfile, setIsAdminProfile] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [component, setComponent] = useState(true);


    /*******************************
     *    SetData User on the      *
     *      State Current          *
     *******************************/
    const dataUserProfile = (user) => {
        setNameProfile(user[0]["userName"])
        setEmailProfile(user[0]["email"])
        setIsAdminProfile(user[0]["isAdmin"])
        setOldPassword(user[0]["password"])
    }

    /*******************************
     *    Clean State Current      *
     *******************************/
    const cleanState = () => {
        setPasswordProfile("")
        setConfirmPasswordProfile("")
        setOldPasswordProfile("")
    }

    /*******************************
     *  HandleSubmit Update User   *
     *******************************/
    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateUserCurrent();
    }


    /*******************************
     *  Disabeld Buttons in events *
     *******************************/
     const disabledButton = (data) => {
        document.getElementById("updateProfile").disabled = data;
    }

    /*******************************
     *    Update Use Current       *
     *******************************/
    const UpdateUserCurrent = () => {

        const stringEmail = validaEmail(emailProfile);
        const stringSenha = passwordProfile === "" ? true : passwordValidator(passwordProfile);

        if (stringEmail) {
            if (stringSenha) {
                if (oldPassword === oldPasswordProfile) {
                    if (passwordProfile === confirmPasswordProfile) {
                        Swal.fire({
                            title: 'Atualizando...'
                        });
                        Swal.showLoading();
                        setTimeout(() => {
                            axios.post(baseAddressUpdateUser, null, {
                                params: {
                                    nome: nameProfile,
                                    email: emailProfile,
                                    password: passwordProfile,
                                    isAdmin: isAdminProfile
                                }
                            }).then(response => {
                                Swal.close()
                                setTimeout(function () {
                                    Swal.fire({
                                        position: 'center',
                                        icon: 'success',
                                        title: response.data,
                                        showConfirmButton: false,
                                        timer: 4000
                                    })
                                    cleanState();
                                }, 2000);
                            }).catch(error => {
                                Swal.close()
                                Swal.fire({
                                    icon: 'error',
                                    showConfirmButton: false,
                                    title: 'Oops... Falha ao atualizar usuário',
                                    text: "Tente novamente ou contate o administrador!",
                                    timer: 3000,
                                })
                                cleanState();
                            });
                        }, 5000)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            showConfirmButton: false,
                            title: 'Oops...',
                            text: 'As senhas devem ser iguais no campo confirma senha.',
                            footer: 'Todos os campos são obrigatórios o preenchimento.',
                            timer: 3000,
                        })

                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        showConfirmButton: false,
                        title: 'Oops...',
                        text: 'A senha antiga está incorreta ',
                        footer: 'Campo obrigatório preenchimento.',
                        timer: 3000,
                    })
                }
            } else {

                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Oops...',
                    text: 'Caracteres inválido para senha.',
                    footer: 'Digite uma senha valida.',
                    timer: 3000,
                })
            }
        }
        else {

            Swal.fire({
                icon: 'error',
                showConfirmButton: false,
                title: 'Oops...',
                text: 'Email invalido!',
                footer: 'Campo obrigatório o preenchimento',
                timer: 3000,
            })
        }
    }

    /*******************************
     *    Verify Role User to      *
     *       Hide Input            *
     *******************************/
    const isAdminOrUser = () => {
        if (localStorage.getItem("isAdmin") === "false") {
            $('#activeIsAdmin').hide()
        } else if (localStorage.getItem("isAdmin") === "true") {
            $('#activeIsAdmin').show()
        } else {
            $('#activeIsAdmin').show()
        }

    }


    /*******************************
     *Get Information User Current *
     *       to Input Load         *
     *******************************/
    const getUserProfile = () => {
        if (component) {
            axios.post(baseAddressProfile, null, {
                params: {
                    nome: userName,
                    email: userEmail,
                }
            }).then(response => {
                dataUserProfile(response.data)
                setComponent(false)
            }).catch(error => {
                console.log(error)
            });
        }
    }

    useEffect(() => {
        document.title = "OCR | Perfil";
        disabledButton(true);
        if (passwordProfile && confirmPasswordProfile && oldPasswordProfile) {
            disabledButton(false);
        }
    }, [passwordProfile, confirmPasswordProfile, oldPasswordProfile], [getUserProfile(), isAdminOrUser()]);


    return (
        <>
            <SideBar />
            <div className="container">
                <section id="content-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form">
                                <div className="row login-form" style={{ margin: '0 auto', marginTop: 35, height: 760 }}>
                                    <form className="container" onSubmit={handleSubmit}>
                                        <img src={logo} alt="SemParar" width={100} style={{ marginLeft: 125, marginBottom: 20 }} />
                                        <div className="col-sm-12 col-md-10 col-lg-8 perfilUser" align="center">
                                            <span className="badge badge-pill badge-perfil">Perfil usuário</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="nameProfile">Usuário</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-nameProfile"><FontAwesomeIcon icon={faUser} /></span>
                                                </div>
                                                <input
                                                    type="text"
                                                    onChange={e => setNameProfile(e.target.value)}
                                                    value={nameProfile}
                                                    className="form-control"
                                                    id="name"
                                                    name="nameProfile"
                                                    placeholder="Enter User Name"
                                                    aria-label="name"
                                                    aria-describedby="addon-wrapping"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="emailProfile">Email</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faEnvelope} /></span>
                                                </div>
                                                <input
                                                    type="email"
                                                    onChange={e => setEmailProfile(e.target.value)}
                                                    value={emailProfile}
                                                    className="form-control"
                                                    id="emailProfile"
                                                    name="emailProfile"
                                                    placeholder="Enter email"
                                                    aria-label="email"
                                                    aria-describedby="addon-wrapping"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="oldpassword">Senha antiga</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-oldpassword"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                </div>
                                                <input
                                                    type="password"
                                                    onChange={e => setOldPasswordProfile(e.target.value)}
                                                    value={oldPasswordProfile}
                                                    name="oldpassword"
                                                    className="form-control"
                                                    id="oldpassword"
                                                    placeholder="old password"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="passwordProfile">Nova Senha</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                </div>
                                                <input
                                                    type="password"
                                                    onChange={e => setPasswordProfile(e.target.value)}
                                                    value={passwordProfile}
                                                    name="passwordProfile"
                                                    className="form-control"
                                                    id="passwordProfile"
                                                    placeholder="Password"
                                                />
                                            </div>
                                            <small id="emailHelp" className="form-text text-muted">Mínimo de 8 caracteres, letra maiúscula, letra minúscula, um dígito e um caractere especial .</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmPasswordProfile">Confirma Nova Senha</label>
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-passwordConfirm"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                </div>
                                                <input
                                                    type="password"
                                                    onChange={e => setConfirmPasswordProfile(e.target.value)}
                                                    value={confirmPasswordProfile}
                                                    className="form-control"
                                                    name="confirmPasswordProfile"
                                                    id="confirmPasswordProfile"
                                                    placeholder="Confirm Password"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group" id="activeIsAdmin">
                                            <label htmlFor="inputState">Admin</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUserShield} /></span>
                                                </div>
                                                <select id="isAdmin" className="form-control" value={isAdminProfile} onChange={e => setIsAdminProfile(e.target.value)}>
                                                    <option value={""} defaultValue>Selecione</option>
                                                    <option value={true} >Sim</option>
                                                    <option value={false}>Não</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn font-weight-bold mt-3" id="updateProfile" style={{ width: '100%', margin: '0 auto', height: 40 }}><FontAwesomeIcon icon={faUserEdit} />Atualizar Registro</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UpdateProfile;