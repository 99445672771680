import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

//AQUI ESTAMOS DEFININDO AS NOSSAS ROTAS E TAMBÉM USANDO O PADRÃO DE HashRouter PARA CONSEGUIR VOLTAR AS ROTAS ANTERIORES
import RoutePrivate from './routePrivate';
import Auth from '../auth/auth';
import Home from '../main/home';
import UserCreate from '../user/userCreate';
import ListUser from '../user/list';
import UpdateProfile from '../user/updateProfile';
import UserEdit from '../user/userEdit';

//PROVIDER USER GLOBAL
import UserProvider from '../context/userContext';


const Routes = () => (
    <UserProvider>
            <BrowserRouter basename='/v2'>
                <Switch>
                    <Route exact path='/login' component={Auth}/>
                    <RoutePrivate path='/home' component={Home}/>
                    <RoutePrivate path='/createuser' component={UserCreate}/>
                    <RoutePrivate path='/userlist' component={ListUser}/>
                    <RoutePrivate path='/userprofile' component={UpdateProfile}/>
                    <RoutePrivate path='/useredit' component={UserEdit}/>
                    <Redirect from='*' to='/home'/>
                </Switch>
            </BrowserRouter>
    </UserProvider>
)

export default Routes;