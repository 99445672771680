import React, { useState, useEffect } from 'react';
import '../auth/auth.css';

import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import { faUser, faEnvelope, faUnlockAlt, faUserShield, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validaEmail, passwordValidator } from '../template/validators/validators';

import logo from '../template/img/logo.png';


import SideBar from '../template/sidebar/sidebar';
import NotFound from '../template/Error/notFound';

const userRole = localStorage.getItem("isAdmin");
const RoleCurrent = userRole === "true" ? true : userRole === "null" ? null : false;
const activeOrDisabled = userRole === "true" ? "admin.empresa" : userRole === "null" ? "admin.geral" : "user.comum";
const baseURL = process.env.REACT_APP_CREATE_USER;

function UserCreate(props) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [optsUser] = useState(["Não", "Sim"]);


    const Add = optsUser.map(optsUser => optsUser)

    const handleOptionsIsAdmin = (e) => {
        e.preventDefault();
        const isAdmin = optsUser[e.target.value]
        isAdmin === "Sim" ? setIsAdmin(true) : setIsAdmin(false)
    }

   /*******************************
    *  HandleSubmit Create User   *
    *******************************/
    const handleSubmit = (e) => {
        e.preventDefault();
        createUser();
    }

    const disabledButton = (data) => {
        document.getElementById("usercreate").disabled = data;
    }

    useEffect(() => {
        document.title = "OCR | Novo usuário";
        if(activeOrDisabled === "admin.empresa" || activeOrDisabled === "admin.geral"){
            disabledButton(true);
        }else{
            return false;
        }
        if(name && email && password && confirmPassword){
            disabledButton(false);
        }
    }, [name, email, password, confirmPassword])


   /*******************************
    *    Clean State Current      *
    *******************************/
    const clearState = () => {
        setName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setIsAdmin("")
    }


   /*******************************
    *    Create Use Current       *
    *******************************/
    const createUser = () => {
        const stringEmail = validaEmail(email);
        const stringSenha = passwordValidator(password);

        if (stringEmail) {
            if (stringSenha) {
                if (password === confirmPassword) {

                    Swal.fire({
                        title: 'Criando...'
                    });
                    Swal.showLoading();
                    setTimeout(function () {
                        axios.post(baseURL, null, {
                            params: {
                                "nome": name,
                                "email": email,
                                "password": password,
                                "isAdmin": isAdmin
                            }
    
                        }).then(response => {
                            Swal.close()
                            setTimeout(function () {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: response.data,
                                    showConfirmButton: false,
                                    timer: 4000
                                })
                                clearState();
                            }, 2000);
                        }).catch(error => {
                            Swal.fire({
                                icon: 'error',
                                showConfirmButton: false,
                                title: 'Oops...',
                                text: error.response.data,
                                timer: 3000,
                            })
                        });
                    }, 5000);
                } else {

                    Swal.fire({
                        icon: 'error',
                        showConfirmButton: false,
                        title: 'Oops...',
                        text: 'As senhas devem ser iguais no campo confirma senha.',
                        footer: 'Todos os campos são obrigatórios o preenchimento.',
                        timer: 3000,
                    })

                }
            } else {

                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Oops...',
                    text: 'Caracteres inválido para senha.',
                    footer: 'Todos os campos são obrigatórios o preenchimento.',
                    timer: 3000,
                })
            }
        }
        else {

            Swal.fire({
                icon: 'error',
                showConfirmButton: false,
                title: 'Oops...',
                text: 'Email invalido!',
                footer: 'Todos os campos são obrigatórios o preenchimento',
                timer: 3000,
            })
        }
    }


    return (
        <>
            { RoleCurrent || RoleCurrent === null ?

                (
                    <>
                        <SideBar />
                        <div className="container">
                            <section id="content-wrapper">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form">
                                            <div className="row login-form" style={{ margin: '0 auto', marginTop: 75, height: 700 }}>
                                                <form className="container" onSubmit={handleSubmit}>
                                                    <img src={logo} alt="SemParar" width={100} style={{ marginLeft: 125, marginBottom: 20 }} />
                                                    <div className="col-sm-12 col-md-10 col-lg-8 updateUser" align="center">
                                                        <span className="badge badge-pill badge-user">Novo usuário</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="name">Usuário</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-name"><FontAwesomeIcon icon={faUser} /></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                onChange={e => setName(e.target.value)}
                                                                value={name}
                                                                className="form-control"
                                                                id="name"
                                                                name="name"
                                                                placeholder="Enter User Name"
                                                                aria-label="name"
                                                                aria-describedby="addon-wrapping"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-email"><FontAwesomeIcon icon={faEnvelope} /></span>
                                                            </div>
                                                            <input
                                                                type="email"
                                                                onChange={e => setEmail(e.target.value)}
                                                                value={email}
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                placeholder="Enter email"
                                                                aria-label="email"
                                                                aria-describedby="addon-wrapping"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="senha">Senha</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                            </div>
                                                            <input
                                                                type="password"
                                                                onChange={e => setPassword(e.target.value)}
                                                                value={password}
                                                                name="password"
                                                                className="form-control"
                                                                id="senha"
                                                                placeholder="Password"
                                                                required
                                                            />
                                                        </div>
                                                        <small id="emailHelp" className="form-text text-muted">Mínimo de 8 caracteres, letra maiúscula, letra minúscula, um dígito e um caractere especial .</small>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="senhaConfirm">Confirma Senha</label>
                                                        <div className="input-group flex-nowrap">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-passwordConfirm"><FontAwesomeIcon icon={faUnlockAlt} /></span>
                                                            </div>
                                                            <input
                                                                type="password"
                                                                onChange={e => setConfirmPassword(e.target.value)}
                                                                value={confirmPassword}
                                                                className="form-control"
                                                                name="confirmPassword"
                                                                id="senhaConfirm"
                                                                placeholder="Confirm Password"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputState">Admin</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="addon-wrapping-password"><FontAwesomeIcon icon={faUserShield} /></span>
                                                            </div>
                                                            <select id="isAdmin" className="form-control" onChange={e => handleOptionsIsAdmin(e)}>
                                                                {
                                                                    Add.map((optsUser, key) => <option key={key} value={key}> {optsUser} </option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-danger font-weight-bold mt-3" id="usercreate" style={{ width: '100%', margin: '0 auto', height: 40 }}><FontAwesomeIcon icon={faUserPlus} /> Criar Usuário</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </>
                )
                :
                (
                    <NotFound />
                )
            }
        </>
    )

}
export default UserCreate;