import React, { useEffect } from 'react'

import axios from 'axios'

import './sidebar.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

import { useHistory, Link } from 'react-router-dom';
// import { useUser } from '../../context/userContext'
import { faUsersCog, faBars, faHome, faPowerOff, faListUl, faUserEdit, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoSemparar from '../img/LogoSemParar.png'

const baseAddressLogAws = process.env.REACT_APP_URL_LOG_AWS;

const $ = require('jquery');

function SideBar(props) {

    // const { user } = useUser();
    const history = useHistory();
    const userCurrent = localStorage.getItem("user");

    function RedirectTo() {
        history.push("/login")
    }

    const isAdminOrUser = () => {
        if (localStorage.getItem("isAdmin") === "false") {
            $('#userCreate').hide()
            $('#userlist').hide()
            $('#userEdit').hide()
        } else if (localStorage.getItem("isAdmin") === "true") {
            $('#userCreate').show()
            $('#userlist').hide()
        } else {
            $('#userCreate').show()
            $('#userlist').show()
            $('#userEdit').show()
        }
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const welcomeUser = () => {
        if (localStorage.getItem("welcome") === "true") {
            localStorage.setItem("welcome", false)
            setTimeout(() => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Bem-vindo <br> Autenticado com sucesso',
                    showConfirmButton: false,
                    timer: 4000
                })
                    ;
            }, 100);
            setTimeout(() => { refreshPage(); }, 1300);
            //Parâmetro Endpoint Produção
            const url = baseAddressLogAws;
            // Headres Produção
            const _headersAws = {
                "x-api-key": "MqFwGon7DU5HrxyUt6hP2XdEoTa5GCL9wZOZvL2j",
                "Content-Type": "application/json",
            };
            let body = {
                'user': userCurrent
            }
            axios.post(url, body, {
                headers: _headersAws
            }).then(function (response) {
                return true
            }).catch(function (error) {
                return false;
            })
        }
    }

    const eventClick = () => {
        const button = document.getElementById('sidebar-toggle');
        const wrapper = document.getElementById('wrapper');

        button.addEventListener('click', (e) => {
            e.preventDefault();
            wrapper.classList.toggle('toggled');
        });
    }

    useEffect(() => {
        isAdminOrUser();
        welcomeUser();
        eventClick();
    });

    function Logout() {

        Swal.fire({
            title: 'Tem certeza que deseja sair?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: `Sim`,
            denyButtonText: `Não`,
        }).then(result => {
            if (result.value) {
                sessionStorage.clear()
                localStorage.clear()
                RedirectTo();
            } else if (result.isDenied) {
                return;
            }
        })

    }

    return (
        <div id="wrapper">
            <aside id="sidebar-wrapper">
                <div className="sidebar-brand">
                    <img src={logoSemparar} alt="SemParar" width={80} />
                </div>
                <ul className="sidebar-nav mt-5">
                    <li className="nav-item">
                        <Link to="home"><FontAwesomeIcon icon={faHome} />Home</Link>
                    </li>
                    <li className="nav-item" id="userCreate">
                        <Link to="createuser"><FontAwesomeIcon icon={faUsersCog} />Novo Usuário</Link>
                    </li>
                    <li className="nav-item" id="userEdit">
                        <Link to="useredit"><FontAwesomeIcon icon={faUserEdit} className="pageUserEdit"/>Editar Usuário</Link>
                    </li>
                    <li className="nav-item" id="userlist">
                        <Link to="userlist"><FontAwesomeIcon icon={faListUl} />Lista de Usuários</Link>
                    </li>
                    <li className="nav-item logoutResp" style={{ display: "none" }}>
                        <Link to="#" onClick={Logout}><FontAwesomeIcon icon={faPowerOff} onClick={Logout} /> {userCurrent}</Link>
                    </li>
                </ul>
            </aside>
            <div id="navbar-wrapper">
                <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#2e313a' }}>
                    <div className="container-fluid">
                        <div className="navbar-header">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="navbar-brand" id="sidebar-toggle"><FontAwesomeIcon icon={faBars} /></a>
                        </div>
                        <div className="ml-auto mr-2">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <div className="dropdown">
                                    <button className="btn text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {userCurrent}
                                        <FontAwesomeIcon icon={faChevronDown} className="ml-2"/>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <Link to="userprofile" className="dropdown-item text-light"><FontAwesomeIcon icon={faUserEdit} /> Perfil</Link>
                                        <Link to="#" className="dropdown-item text-light" onClick={Logout}><FontAwesomeIcon icon={faPowerOff} /> Sair</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default SideBar;