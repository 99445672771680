import './template/dependencies/bootstrap/bootstrap'
import Routes from './routes/routes'

const App = () =>  {
  return (
    <div className="App">
      <Routes/>
    </div>
  );
}

export default App;
