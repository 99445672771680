import React from 'react'

import SideBar from '../template/sidebar/sidebar'
import UserCreate from '../user/userCreate'
import ListUser from '../user/list'
import Table from '../table/table'

const Home = () => (

    <div>
        <SideBar>
            <UserCreate/>
            <ListUser/>
        </SideBar>
        <Table/>
    </div>

)

export default Home;